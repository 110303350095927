import { useContext, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { useBlock } from './use-block';

export const useBlockFooter = () => {
	const {
		allMarkdownRemark: { edges: blocks },
	} = useStaticQuery(query);

	return useBlock(blocks);
};

const query = graphql`
	query useBlockFooterQuery {
		allMarkdownRemark(
			filter: { frontmatter: { type: { eq: "block" }, name: { eq: "footer" } } }
		) {
			edges {
				node {
					id
					frontmatter {
						left {
							header
							text
							phone
							email
						}
						center {
							header
							text
							app_store_url
							play_store_url
						}
						right {
							header
							text
							newsletter_label
							newsletter_url
						}
						copyright {
							text
						}
						social_media {
							email
							facebook
							phone
							twitter
						}
					}
					fields {
						locale
					}
				}
			}
		}
	}
`;
