import React from 'react';
import { Helmet } from 'react-helmet';

import { useSiteMeta } from '../hooks/use-site-meta';

const GoogleTagManagerDenied = () => {
	const { gtmTrackingId } = useSiteMeta();

	return (
		<Helmet>
			<script key="gtm-consent-mode-script">{`
				window.dataLayer = window.dataLayer || [];
					function gtag() {
					dataLayer.push(arguments);
				}
				gtag("consent", "default", {
					ad_storage: "denied",
					analytics_storage: "denied",
					functionality_storage: "denied",
					personalization_storage: "denied",
					security_storage: "granted",
					wait_for_update: 500,
				});
				gtag("set", "ads_data_redaction", true);
			`}</script>
			<script key="gtm-setup-script">{`
				(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
				new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
				j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
				'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
				})(window,document,'script','dataLayer','${gtmTrackingId}');
			`}</script>
		</Helmet>
	);
};

export default GoogleTagManagerDenied;
