//
// Scrolls to a dom element smoothly
// 
export const scrollTo = (element) => {
	if (element instanceof HTMLElement) {
		element.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		})
	}
};

//
// Tries to read overall scroll offset
// 
export const getScrollOffsetY = () => {
	return typeof window.pageYOffset === 'number'
		? window.pageYOffset
		: (
			typeof document.documentElement.scrollTop === 'number'
				? document.documentElement.scrollTop
				: document.body.scrollTop
		)
};