import { useStaticQuery, graphql } from 'gatsby';

export const useSiteMeta = () => {
	const {
		site: { siteMetadata: data },
	} = useStaticQuery(query);

	return data;
};

const query = graphql`
	query useSiteMeta {
		site {
			siteMetadata {
				siteUrl
				gtmTrackingId
				fbTrackingId
				hostName
			}
		}
	}
`;
