import { useContext, useRef } from 'react';

import LocaleContext from '../contexts/locale';

export const useBlock = (blocks) => {
	const { currentLocale: locale } = useContext(LocaleContext);

	const allRef = useRef([]);
	const mapRef = useRef({});

	const mapItem = ({ node: { id, fields, frontmatter: data } }) => ({
		...data,
		...fields,
		...{
			id,
		},
	});

	if (allRef.current.length === 0) {
		for (let block of blocks) {
			const item = mapItem(block);
			const { locale } = item;

			mapRef.current[locale] = item;
			allRef.current.push(item);
		}
	}

	return mapRef.current[locale];
};
