exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/about.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-customers-jsx": () => import("./../../../src/templates/customers.jsx" /* webpackChunkName: "component---src-templates-customers-jsx" */),
  "component---src-templates-imprint-jsx": () => import("./../../../src/templates/imprint.jsx" /* webpackChunkName: "component---src-templates-imprint-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/news.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */),
  "component---src-templates-philosophy-jsx": () => import("./../../../src/templates/philosophy.jsx" /* webpackChunkName: "component---src-templates-philosophy-jsx" */),
  "component---src-templates-privacy-jsx": () => import("./../../../src/templates/privacy.jsx" /* webpackChunkName: "component---src-templates-privacy-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-projects-jsx": () => import("./../../../src/templates/projects.jsx" /* webpackChunkName: "component---src-templates-projects-jsx" */),
  "component---src-templates-storytelling-jsx": () => import("./../../../src/templates/storytelling.jsx" /* webpackChunkName: "component---src-templates-storytelling-jsx" */),
  "component---src-templates-technology-jsx": () => import("./../../../src/templates/technology.jsx" /* webpackChunkName: "component---src-templates-technology-jsx" */),
  "component---src-templates-terms-jsx": () => import("./../../../src/templates/terms.jsx" /* webpackChunkName: "component---src-templates-terms-jsx" */)
}

