//
// Gets item from cookie storage.
//
export const getItem = (key) => {
  const cookies = getAllItems();

  return cookies[key] || null;
};

//
// Sets item in cookie storage.
//
export const setItem = (key, value, expires, domain, path, secure) => {
  if (typeof document === 'undefined') {
    return;
  }

  const encoded = encode_(value);
  let expiresDate;

  try {
    const parsed = new Date(expires);

    if (isNaN(parsed.valueOf())) {
     throw new Error(`"${expires}" cannot be converted to date string!`);
    }

    expiresDate = parsed.toUTCString();
  } catch (e) {
    /* noop */
  }

  let cookie = `${key}=${encoded}`;

  if (expiresDate) {
    cookie = `${cookie};expires=${expiresDate}`;
  }

  if (domain) {
    cookie = `${cookie};domain=${domain}`;
  }

  if (path) {
    cookie = `${cookie};path=${path}`;
  }

  if (secure) {
    cookie = `${cookie};secure`;
  }
  
  document.cookie = cookie;
};

//
// Removes item from cookie storage.
//
export const removeItem = (key) => {
  setItem(key, '', -1);
};

//
// Gets all items from cookie storage.
//
export const getAllItems = () => {
  if (typeof document === 'undefined') {
    return {};
  }

  const items = {};
  const cookies = document.cookie.split('; ');
  if (cookies.length === 1 && cookies[0] === '') {
    return items;
  }

  for (let i = 0; i < cookies.length; i++) {
    const [key, value] = cookies[i].split('=');
    items[key] = decode_(value);
  }

  return items;
};

//
// Removes all items from cookie storage.
//
export const removeAllItems = () => {
  for (let key in getAllItems()) {
    removeItem(key);
  }
};

//
// Stringifies and uri encodes a value.
//
const encode_ = (value) => {
  try {
    return encodeURIComponent(JSON.stringify(value));
  } catch (e) {
    return null;
  }
};

//
// Decodes a stringified and uri encoded value.
//
const decode_ = (value) =>  {
  try {
    return JSON.parse(decodeURIComponent(value));
  } catch (e) {
    return null;
  }
};