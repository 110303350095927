import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { useSiteMeta } from '../hooks/use-site-meta';

export const trackPageViewFB = () => {
	// requires analytics to be loaded
	// @see: CookieConsent component
	if (typeof ftrack === 'undefined') {
		return;
	}

	// eslint-disable-next-line
	ftrack('ViewContent');
};

const FacebookPixelGranted = () => {
	const { hostName, fbTrackingId } = useSiteMeta();

	return (
		<Fragment>
			<Helmet>
				<script key="fb-setup-script">
					{`
				!function(f,b,e,v,n,t,s)
				{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				n.callMethod.apply(n,arguments):n.queue.push(arguments)};
				if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
				n.queue=[];t=b.createElement(e);t.async=!0;
				t.src=v;s=b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t,s)}(window, document,'script',
				'https://connect.facebook.net/en_US/fbevents.js');
				
				function ftrack(eventName) {
					var pagePath = location.pathname + location.search + location.hash;
					if (location.hostname.indexOf('${hostName}') >= 0) {
						fbq('track', eventName);
					}
				}

				fbq('init', '${fbTrackingId}');
				fbq.disablePushState = true;
				ftrack('PageView');
			`}
				</script>
			</Helmet>
			<noscript key="fb-no-script">{`
				<img
					width="1"
					height="1"
					style="display:none"
					src={https://www.facebook.com/tr?id=${fbTrackingId}&ev=PageView&noscript=1
				/>
			`}</noscript>
		</Fragment>
	);
};

export default FacebookPixelGranted;
