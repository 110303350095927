import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import LocaleContext from '../contexts/locale';
import MetaTags from '../components/meta-tags';
import { getScrollOffsetY } from '../utils/dom';
import { useBlockGeneral } from '../hooks/use-block-general';
import { useTranslations } from '../hooks/use-translations';
import CookieConsent from '../components/cookie-consent';

import Footer from './footer';
import Header, { SubHeader } from './header';
import styles from './layout.module.scss';
import GoogleTagManagerDenied from './google-tag-manager-denied';

//
// LAYOUT
//
const Layout = ({ children, pageContext: { slug, locale } }) => {
	const { setCurrentLocale } = useContext(LocaleContext);
	const [isFixed, setIsFixed] = useState(false);

	useEffect(() => {
		// note: this is to catch users navigating between
		// different locales using the browser back button
		setCurrentLocale(locale);
	}, [locale /* only run effect when language changes! */]);

	useEffect(() => {
		const handleScroll = (/* event */) => {
			setIsFixed(getScrollOffsetY() > 0);
		};

		document.addEventListener('scroll', handleScroll);

		return () => {
			document.removeEventListener('scroll', handleScroll);
		};
	}, [slug /* only run effect on page changes for recursion detection! */]);

	return (
		<div id="top" className={styles.wrapper}>
			<Header isFixed={isFixed} />
			<main
				className={isFixed ? `${styles.main} ${styles.mainIsFixed}` : `${styles.main}`}>
				{children}
			</main>
			<Footer />
			<MetaTags page={slug} />
			<GoogleTagManagerDenied />
			<CookieConsent />
		</div>
	);
};

export default Layout;

//
// GO BACK
//
export const GoBack = ({ title, to }) => {
	const { back_to_overview_label } = useBlockGeneral();
	const defaultLabelBack = useTranslations('BACK_TO_OVERVIEW');

	return <Link to={to}>&larr; {back_to_overview_label || defaultLabelBack}</Link>;
};

GoBack.defaultProps = {
	to: null,
};

GoBack.propTypes = {
	to: PropTypes.string.isRequired,
};

//
// SUB PAGE
//
export const SubPage = ({ children, title }) => (
	<div className={styles.subPage}>
		<SubHeader title={title} />
		{children}
	</div>
);

SubPage.defaultProps = {
	title: null,
};

SubPage.propTypes = {
	title: PropTypes.string.isRequired,
};
