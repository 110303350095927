import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import { detectLanguage } from '../utils/i18n';

const LOCALES = {
	de: true,
	en: true,
	it: false,
};

const validateLocale = (locale) => {
	locale = typeof locale !== 'string' ? detectLanguage() : locale;

	if (locale in LOCALES) {
		return locale;
	}

	return DEFAULT_LOCALE;
};

export const ALL_LOCALES = Object.keys(LOCALES).filter((key) => !!LOCALES[key]);
export const DEFAULT_LOCALE = 'de';

export const LocaleProvider = ({ children, locale }) => {
	let [currentLocale, setCurrentLocale] = useState(validateLocale(locale));

	const validateSetter = (locale) => {
		locale = validateLocale(locale);
		setCurrentLocale(locale);
	};

	const value = {
		currentLocale,
		locales: ALL_LOCALES,
		defaultLocale: DEFAULT_LOCALE,
		setCurrentLocale: validateSetter,
	};

	return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};

LocaleProvider.defaultProps = {
	locale: DEFAULT_LOCALE,
};

LocaleProvider.propTypes = {
	locale: PropTypes.string,
};

const LocaleContext = createContext({
	defaultLocale: DEFAULT_LOCALE,
	currentLocale: DEFAULT_LOCALE,
	setCurrentLocale: () => {},
	locales: ALL_LOCALES,
});

export default LocaleContext;
