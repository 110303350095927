import CustomLayout from './wrapPageElement';
import './src/styles/main.scss';

//
// Add Google Analytics tracking for page changes as described here:
//
// https://www.gatsbyjs.org/docs/browser-apis/#onRouteUpdate
// https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
//
import { trackPageViewFB } from './src/components/facebook-pixel-granted';

//
// Implement the `shouldUpdateScroll` hook avoiding scroll on route change:
// https://www.gatsbyjs.org/docs/browser-apis/#shouldUpdateScroll
//
export const shouldUpdateScroll = ({
	routerProps: { location },
	getSavedScrollPosition,
}) => {
	const savedPosition = getSavedScrollPosition(location);
	const scrollTo = savedPosition || [0, 0];
	window.scrollTo(...scrollTo);
	return false;
};

//
// Implement the `wrapPageElement` hook to avoid remounting of layout:
// https://www.gatsbyjs.org/docs/browser-apis/#wrapPageElement
//
export const wrapPageElement = CustomLayout;
export const onRouteUpdate = ({ prevLocation }) => {
	// only track for page updates, not initial one
	// @see: ./src/components/google-analytics.jsx
	if (prevLocation === null) {
		return;
	}

	trackPageViewFB();
};
