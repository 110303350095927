import { graphql, useStaticQuery } from 'gatsby';

import { useBlock } from './use-block';

export const useBlockHeader = () => {
	const {
		allMarkdownRemark: { edges: blocks },
	} = useStaticQuery(query);

	return useBlock(blocks);
};

const query = graphql`
	query useBlockHeaderQuery {
		allMarkdownRemark(
			filter: { frontmatter: { type: { eq: "block" }, name: { eq: "header" } } }
		) {
			edges {
				node {
					id
					frontmatter {
						button {
							url
							label
						}
					}
					fields {
						locale
					}
				}
			}
		}
	}
`;
