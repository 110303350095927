import React, { useEffect } from 'react';

const GoogleTagManagerGranted = () => {
	useEffect(() => {
		if (typeof window.dataLayer !== 'undefined') {
			window.dataLayer.push({ event: 'constent_granted' });
		}
	}, []);

	return null;
};

export default GoogleTagManagerGranted;
