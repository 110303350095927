import React, { useContext } from 'react';
import { Link } from 'gatsby';

import LocaleContext from '../contexts/locale';

import {
	MailIcon,
	PhoneIcon,
	AppleIcon,
	AndroidIcon,
	TwitterIcon,
	FacebookIcon,
} from './icons';
import { useTranslations } from '../hooks/use-translations';
import { useBlockFooter } from '../hooks/use-block-footer';
import { MENU_TYPE_MISC, usePagesByMenu } from '../hooks/use-pages';

import styles from './footer.module.scss';

//
// FOOTER
//
const Footer = () => (
	<footer className={styles.footer}>
		<div className={styles.footerRowFirst}>
			<div className={styles.container}>
				<div className={styles.footerTable}>
					<div className={`${styles.footerCol} ${styles.footerColLeft}`}>
						<FooterLeft />
					</div>
					<div className={`${styles.footerCol} ${styles.footerColCenter}`}>
						<FooterCenter />
					</div>
					<div className={`${styles.footerCol} ${styles.footerColRight}`}>
						<FooterRight />
					</div>
				</div>
			</div>
		</div>

		<div className={styles.footerRowSecond}>
			<div className={styles.container}>
				<div className={styles.footerTable}>
					<div className={`${styles.footerCol} ${styles.footerColLeft}`}>
						<FooterMenu />
					</div>
					<div className={`${styles.footerCol} ${styles.footerColCenter}`}>
						<FooterSocialMedia />
					</div>
					<div className={`${styles.footerCol} ${styles.footerColRight}`}>
						<FooterLanguages />
						<FooterCopyright />
					</div>
				</div>
			</div>
		</div>
	</footer>
);

//
// FOOTER LEFT
//
const FooterLeft = () => {
	const {
		left: { header, text, email, phone },
	} = useBlockFooter();

	const phoneSanitized = phone.replace(/\s+/, '');
	const emailSanitized = email.replace(/\s+/, '');

	const prefixPhone = useTranslations('PREFIX_PHONE');
	const prefixEmail = useTranslations('PREFIX_EMAIL');

	return (
		<div>
			<h4>{header}</h4>
			<p>{text}</p>
			<div>
				<span>{prefixPhone} </span>
				<a href={`tel:${phoneSanitized}`} className={styles.footerLinkContact}>
					{phone}
				</a>
			</div>
			<div>
				<span>{prefixEmail} </span>
				<a href={`mailto:${emailSanitized}`} className={styles.footerLinkContact}>
					{email}
				</a>
			</div>
		</div>
	);
};

//
// FOOTER CENTER
//
const FooterCenter = () => {
	const {
		center: { header, text, app_store_url, play_store_url },
	} = useBlockFooter();

	return (
		<div>
			<h4>{header}</h4>
			<p>{text}</p>
			<a href={app_store_url} className={styles.footerLinkApp}>
				<AppleIcon className={styles.footerIconApp} />
			</a>
			<a href={play_store_url} className={styles.footerLinkApp}>
				<AndroidIcon className={styles.footerIconApp} />
			</a>
		</div>
	);
};

//
// FOOTER RIGHT
//
const FooterRight = () => {
	const {
		right: { header, text, newsletter_label, newsletter_url },
	} = useBlockFooter();

	return (
		<div>
			<h4>{header}</h4>
			<p>{text}</p>
			<a href={newsletter_url} className={styles.footerNewsletterButton}>
				{newsletter_label}
			</a>
		</div>
	);
};

//
// FOOTER LANGUAGES
//
const FooterLanguages = () => {
	const { locales, currentLocale, defaultLocale, setCurrentLocale } =
		useContext(LocaleContext);

	const onClick = ({ target: { hreflang: locale } }) => setCurrentLocale(locale);

	return (
		<nav className={styles.footerLanguages}>
			{locales.map((locale, index) => (
				<Link
					key={index}
					rel="alternate"
					hrefLang={locale}
					onClick={onClick}
					to={locale !== defaultLocale ? `/${locale}` : '/'}
					className={
						locale !== currentLocale
							? `${styles.footerLanguagesItem}`
							: `${styles.footerLanguagesItem} ${styles.footerLanguagesItemActive}`
					}>
					{locale}
				</Link>
			))}
		</nav>
	);
};

//
// FOOTER COPYRIGHT
//
const FooterCopyright = () => {
	const {
		copyright: { text },
	} = useBlockFooter();

	const year = new Date().getFullYear();

	return (
		<div className={styles.footerCopyright}>
			&copy; {text} {year}
		</div>
	);
};

//
// FOOTER MENU
//
const FooterMenu = () => {
	return usePagesByMenu(MENU_TYPE_MISC)
		.filter(({ currentPage }) => currentPage === 1)
		.map((page) => (
			<Link
				to={page.slug}
				key={page.slug}
				className={styles.footerMenuItem}
				activeClassName={styles.footerMenuItemActive}>
				{page.title}
			</Link>
		));
};

//
// FOOTER SOCIAL MEDIA
//
const FooterSocialMedia = () => {
	const {
		social_media: { phone, email, twitter, facebook },
	} = useBlockFooter();

	const phoneSanitized = phone.replace(/\s+/, '');
	const emailSanitized = email.replace(/\s+/, '');

	return (
		<div>
			<a href={`mailto:${emailSanitized}`} className={styles.footerSocialMediaLink}>
				<MailIcon className={styles.footerSocialMediaIcon} />
			</a>
			<a href={`tel:${phoneSanitized}`} className={styles.footerSocialMediaLink}>
				<PhoneIcon className={styles.footerSocialMediaIcon} />
			</a>
			<a href={twitter} className={styles.footerSocialMediaLink}>
				<TwitterIcon className={styles.footerSocialMediaIcon} />
			</a>
			<a href={facebook} className={styles.footerSocialMediaLink}>
				<FacebookIcon className={styles.footerSocialMediaIcon} />
			</a>
		</div>
	);
};

export default Footer;
