import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'gatsby';

import { useTranslations } from '../hooks/use-translations';
import { usePageByKey } from '../hooks/use-pages';
import { getItem, setItem } from '../utils/cookies';

import FacebookPixelGranted from './facebook-pixel-granted';
import GoogleTagManagerGranted from './google-tag-manager-granted';
import styles from './cookie-consent.module.scss';

const CC_KEY = '__ccs';
const CC_VALUE = 'yes';

const CookieConsent = () => {
	const [isAccepted, setIsAccepted] = useState(false);
	const [isMounted, setIsMounted] = useState(false);
	const onAccept = () => {
		setItem(
			CC_KEY,
			CC_VALUE,
			new Date(new Date().setFullYear(new Date().getFullYear() + 1))
		);
		setIsAccepted(true);
	};
	const { slug } = usePageByKey('privacy');

	const textConsent = useTranslations('COOKIES_CONSENT');
	const labelButton = useTranslations('COOKIES_BUTTON');
	const labelLink = useTranslations('COOKIES_PRIVACY');
	const style = {
		display: isMounted ? 'block' : 'none',
	};

	useEffect(() => {
		setIsAccepted(getItem(CC_KEY) === CC_VALUE);
		setIsMounted(true);
	}, []);

	if (isAccepted) {
		return (
			<Fragment>
				<GoogleTagManagerGranted />
				<FacebookPixelGranted />
			</Fragment>
		);
	}

	return (
		<div className={styles.wrapper} style={style}>
			<div className={styles.consent}>{textConsent}</div>
			<div className={styles.buttons}>
				<button onClick={onAccept} className={styles.accept}>
					{labelButton}
				</button>
				<Link to={slug} className={styles.privacy}>
					{labelLink}
				</Link>
			</div>
		</div>
	);
};

export default CookieConsent;
