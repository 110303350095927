import React from 'react';
import PropTypes from 'prop-types';
import { SvgLoader } from 'react-svgmt';

import PlayComponent from '../icons/play.svg';
import MailComponent from '../icons/mail.svg';
import PlusComponent from '../icons/plus.svg';
import UserComponent from '../icons/user.svg';
import MinusComponent from '../icons/minus.svg';
import PauseComponent from '../icons/pause.svg';
import PhoneComponent from '../icons/phone.svg';
import AppleComponent from '../icons/apple.svg';
import AndroidComponent from '../icons/android.svg';
import TwitterComponent from '../icons/twitter.svg';
import FacebookComponent from '../icons/facebook.svg';
import LogoFullComponent from '../icons/logo-full.svg';
import DownloadComponent from '../icons/download.svg';
import ArrowUpComponent from '../icons/arrow-up.svg';
import ArrowDownComponent from '../icons/arrow-down.svg';
import ArrowLeftComponent from '../icons/arrow-left.svg';
import ArrowRightComponent from '../icons/arrow-right.svg';
import SearchComponent from '../icons/search.svg';
import CloseComponent from '../icons/close.svg';
import LinkComponent from '../icons/link.svg';

import styles from './icons.module.scss';

export const UserIcon = (props) => <Icon component={UserComponent} {...props} />;

export const PlayIcon = (props) => <Icon component={PlayComponent} {...props} />;

export const MailIcon = (props) => <Icon component={MailComponent} {...props} />;

export const PlusIcon = (props) => <Icon component={PlusComponent} {...props} />;

export const MinusIcon = (props) => <Icon component={MinusComponent} {...props} />;

export const PauseIcon = (props) => <Icon component={PauseComponent} {...props} />;

export const PhoneIcon = (props) => <Icon component={PhoneComponent} {...props} />;

export const TwitterIcon = (props) => <Icon component={TwitterComponent} {...props} />;

export const FacebookIcon = (props) => <Icon component={FacebookComponent} {...props} />;

export const AppleIcon = (props) => <Icon component={AppleComponent} {...props} />;

export const AndroidIcon = (props) => <Icon component={AndroidComponent} {...props} />;

export const LogoFullIcon = (props) => <Icon component={LogoFullComponent} {...props} />;

export const ArrowUpIcon = (props) => <Icon component={ArrowUpComponent} {...props} />;

export const ArrowDownIcon = (props) => (
	<Icon component={ArrowDownComponent} {...props} />
);

export const ArrowLeftIcon = (props) => (
	<Icon component={ArrowLeftComponent} {...props} />
);

export const ArrowRightIcon = (props) => (
	<Icon component={ArrowRightComponent} {...props} />
);

export const DownloadIcon = (props) => <Icon component={DownloadComponent} {...props} />;

export const SearchIcon = (props) => <Icon component={SearchComponent} {...props} />;

export const CloseIcon = (props) => <Icon component={CloseComponent} {...props} />;

export const LinkIcon = (props) => <Icon component={LinkComponent} {...props} />;

export const SVG = (props) => {
	const { path, className = '' } = props;
	props = {
		...props,
		...{
			className: `${styles.icon} ${className}`,
		},
	};

	delete props.path;

	return <SvgLoader path={path} {...props} />;
};

SVG.defaultProps = {
	path: null,
	className: '',
};

SVG.propTypes = {
	path: PropTypes.string.isRequired,
	className: PropTypes.string,
};

export const MenuIcon = ({ isActive, className }) => (
	<svg
		viewBox="0 0 40 40"
		className={`
			${styles.menu}
			${className ? ` ${className}` : ''}
			${isActive ? ` ${styles.menuActive}` : ''}
		`}>
		<g className={styles.menuLines}>
			<line x1="0" x2="40" y1="5" y2="5" className={styles.menuLine1}></line>
			<line x1="0" x2="40" y1="20" y2="20" className={styles.menuLine2}></line>
			<line x1="0" x2="40" y1="20" y2="20" className={styles.menuLine3}></line>
			<line x1="0" x2="40" y1="35" y2="35" className={styles.menuLine4}></line>
		</g>
	</svg>
);

MenuIcon.propTypes = {
	isActive: PropTypes.bool.isRequired,
	className: PropTypes.string,
};

const Icon = ({ component: Component, className = '', ...props }) => (
	<Component className={`${styles.icon} ${className}`} {...props} />
);

Icon.propTypes = {
	component: PropTypes.elementType.isRequired,
	className: PropTypes.string,
};

export default Icon;
