import { useContext, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { useBlock } from './use-block';

export const useBlockGeneral = () => {
	const {
		allMarkdownRemark: { edges: blocks },
	} = useStaticQuery(query);

	return useBlock(blocks);
};

const query = graphql`
	query useBlockGeneralQuery {
		allMarkdownRemark(
			filter: { frontmatter: { type: { eq: "block" }, name: { eq: "general" } } }
		) {
			edges {
				node {
					id
					frontmatter {
						company_name
						faq_title
						back_to_overview_label
					}
					fields {
						locale
					}
				}
			}
		}
	}
`;
