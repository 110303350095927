import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { MenuIcon, LogoFullIcon } from './icons';
import { MENU_TYPE_MAIN, usePagesByMenu } from '../hooks/use-pages';
import { useBlockHeader } from '../hooks/use-block-header';

import styles from './header.module.scss';

//
// HEADER
//
const Header = ({ isFixed }) => (
	<header
		className={isFixed ? `${styles.header} ${styles.headerIsFixed}` : `${styles.header}`}>
		<div className={styles.container}>
			<Navigation isFixed={isFixed} />
		</div>
	</header>
);

Header.defaultProps = {
	isFixed: false,
};

Header.propTypes = {
	isFixed: PropTypes.bool.isRequired,
};

export default Header;

//
// SUB HEADER
//
export const SubHeader = ({ title }) => (
	<div className={styles.subHeader}>
		<div className={styles.container}>
			<h1 className={styles.subHeaderTitle}>{title}</h1>
		</div>
	</div>
);

SubHeader.defaultProps = {
	title: null,
};

SubHeader.propTypes = {
	title: PropTypes.string.isRequired,
};

//
// LOGO
//
const Logo = ({ icon: LogoIcon, isSmall }) => (
	<div className={styles.logo}>
		<Link to="/" className={styles.logoLink}>
			<LogoIcon
				width="100%"
				height="100%"
				className={
					!isSmall ? `${styles.logoSvg}` : `${styles.logoSvg} ${styles.logoSvgSmall}`
				}
			/>
		</Link>
	</div>
);

Logo.defaultProps = {
	isSmall: false,
	icon: LogoFullIcon,
};

Logo.propTypes = {
	isSmall: PropTypes.bool,
	icon: PropTypes.func,
};

//
// NAVIGATION
//
const Navigation = ({ isFixed }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggle = (event) => {
		event.preventDefault();
		setIsOpen(!isOpen);
	};

	const handleClose = (event) => {
		setIsOpen(false);
	};

	useEffect(() => {
		// close menu automatically on rerenders
		// if it's still open (f.e. page changes)
		setIsOpen(false);
	}, []);

	return (
		<nav className={isFixed ? `${styles.nav} ${styles.navIsFixed}` : `${styles.nav}`}>
			<div className={styles.navLogo}>
				<Logo isSmall={isFixed} />
			</div>
			<div className={styles.navMenuToggle}>
				<a
					onClick={handleToggle}
					href={!isOpen ? '#menu' : ''}
					className={styles.navToggle}>
					<MenuIcon isActive={isOpen} className={styles.navToggleSvg} />
				</a>
			</div>
			<div
				id="menu"
				role="button"
				tabIndex="-1"
				onClick={handleClose}
				className={styles.navMenuWrapper}>
				<Menu isOpen={isOpen} />
			</div>
		</nav>
	);
};

Navigation.defaultProps = {
	isFixed: false,
};

Navigation.propTypes = {
	isFixed: PropTypes.bool.isRequired,
};

//
// MENU
//
const Menu = ({ isOpen }) => {
	const {
		button: { url, label },
	} = useBlockHeader();
	const target =
		typeof url === 'string' && /^https?:\/\//.test(url) ? '_blank' : undefined;

	return (
		<div className={isOpen ? `${styles.menu} ${styles.menuIsOpen}` : `${styles.menu}`}>
			{usePagesByMenu(MENU_TYPE_MAIN)
				.filter(({ currentPage }) => currentPage === 1)
				.map(({ slug, title, menuTitle = title }, key) => (
					<Link
						to={slug}
						key={key}
						partiallyActive={true}
						activeClassName={styles.menuItemLinkActive}
						className={`${styles.menuItem} ${styles.menuItemLink}`}>
						{menuTitle}
					</Link>
				))}

			<div key={url} className={`${styles.menuItem} ${styles.menuItemExtra}`}>
				<a href={url} target={target} className={styles.menuItemButton} rel="noreferrer">
					{label}
				</a>
			</div>
		</div>
	);
};
