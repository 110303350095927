import { useRef, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import LocaleContext from '../contexts/locale';

export const MENU_TYPE_MAIN = 'main';
export const MENU_TYPE_MISC = 'misc';

export const usePageByKey = (key) => {
	const { keyMap } = usePages();

	return keyMap[key] || null;
};

export const usePageBySlug = (slug) => {
	const { slugMap } = usePages();

	return slugMap[slug] || null;
};

export const usePagesByMenu = (menu) => {
	const { menuMap } = usePages();

	return menuMap[menu] || [];
};

export const usePages = () => {
	const {
		allSitePage: { edges: pages },
	} = useStaticQuery(query);
	const { currentLocale: locale } = useContext(LocaleContext);

	const allRef = useRef([]);
	const keyMapRef = useRef({});
	const menuMapRef = useRef({});
	const slugMapRef = useRef({});
	const localeMapRef = useRef({});

	const mapItem = ({ node: { context } }) => context;

	if (allRef.current.length === 0) {
		for (let page of pages) {
			let {
				node: {
					context: { key, slug, menu, locale, currentPage },
				},
			} = page;

			menu = menu || `__unknown__`;

			const item = mapItem(page);

			localeMapRef.current[locale] = localeMapRef.current[locale] || [];
			menuMapRef.current[locale] = menuMapRef.current[locale] || {};
			keyMapRef.current[locale] = keyMapRef.current[locale] || {};

			menuMapRef.current[locale][menu] = menuMapRef.current[locale][menu] || [];
			menuMapRef.current[locale][menu].push(item);
			localeMapRef.current[locale].push(item);

			if (currentPage === 1) {
				keyMapRef.current[locale][key] = item;
			}

			slugMapRef.current[slug] = item;
			allRef.current.push(item);
		}
	}

	return {
		items: localeMapRef.current[locale],
		keyMap: keyMapRef.current[locale],
		menuMap: menuMapRef.current[locale],
		slugMap: slugMapRef.current,
	};
};

const query = graphql`
	query usePagesQuery {
		allSitePage(
			sort: { context: { order: ASC } }
			filter: { context: { type: { in: ["page", "article", "project"] } } }
		) {
			edges {
				node {
					context {
						key
						type
						menu
						order

						slug
						title
						locale
						parent
						description
						menuTitle

						skipItems
						limitItems
						totalPages
						currentPage
					}
				}
			}
		}
	}
`;
