import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { usePageBySlug, usePages } from '../hooks/use-pages';
import { useTranslations } from '../hooks/use-translations';
import LocaleContext from '../contexts/locale';
import { useBlockGeneral } from '../hooks/use-block-general';

const Metatags = ({ page }) => {
	let { title, parent, description, currentPage } =
		usePageBySlug(page) ||
		{
			/* 404 */
		};

	const { currentLocale: locale } = useContext(LocaleContext);

	const { slugMap } = usePages();

	const { company_name } = useBlockGeneral();

	const labelPage = useTranslations('PAGE');
	const textNotFound = useTranslations('NOTFOUND_TEXT');
	const textFallbackCompany = useTranslations('COMPANY_NAME');

	const titles = [title];

	const pageNumber = `${labelPage} ${currentPage}`;
	titles.push(currentPage > 1 ? pageNumber : '');

	while (parent) {
		const page = slugMap[parent];
		titles.push(page.title);
		parent = page.parent;
	}

	// titles.push(`${company_name || textFallbackCompany}`);

	title = titles.filter(Boolean).join(' | ');
	description = description || textNotFound;

	return (
		<Helmet>
			<html lang={locale} />
			<title>{title}</title>
			<meta name="robots" content="index, follow" />
			<meta name="description" content={description} />
			<meta name="og:locale" content={locale} />
			<meta name="og:type" content="website" />
			<meta name="og:title" content={title} />
			<meta name="og:description" content={description} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
			<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
			<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
			<link rel="manifest" href="/site.webmanifest" />
			<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ff6316" />
			<meta name="msapplication-TileColor" content="#191919" />
			<meta name="theme-color" content="#ffffff" />
		</Helmet>
	);
};

Metatags.defaultProps = {
	page: null,
};

Metatags.propTypes = {
	page: PropTypes.string,
};

export default Metatags;
